import {createRouter, createWebHistory} from "vue-router";
import {useMessageStore} from './stores/message-store';

// https://stackoverflow.com/a/66695008
import i18n from "./i18n";
const {t} = i18n.global;

import {app} from './main';

const router = createRouter({
    //mode: "history",
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            alias: '/dashboard', // just needed for dev, because my vite seems to use old files for '/'
            component: () => import('./components/dashboard.vue'),
            // FIXME: remove with prototype2
            meta: { requiresAuth: true, requiresH100: true }
        },
        /*{
            path: '/curve',
            component: () => import('./components/curve')
        },*/
        {
            path: '/video',
            component: () => import('./components/video.vue'),
            meta: {requiresAuth: true, headerMenu: "videos"}
        },
        {
            path: '/videos',
            component: () => import('./components/videos.vue'),
            meta: {requiresAuth: true, headerMenu: "videos"}
        },
        {
            path: '/settings',
            component: () => import('./components/settings.vue'),
            meta: {requiresAuth: true, headerMenu: 'account'}
        },
        {
            path: '/support',
            component: () => import('./components/support.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/faq',
            component: () => import('./components/faq.vue'),
            meta: {requiresAuth: true, headerMenu: 'help'}
        },
        {
            path: '/imprint',
            component: () => import('./components/imprint.vue'),
            meta: {requiresAuth: false}
        },
        {
            path: '/terms',
            component: () => import('./components/terms.vue'),
            meta: {requiresAuth: false}
        },
        {
            path: '/requirements',
            component: () => import('./components/requirements.vue'),
            meta: {requiresAuth: false}
        },
        {
            path: '/support-user',
            // handled by a common page
            component: () => import("./components/support-messages.vue"),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/support-me',
            // handled by a common page
            component: () => import("./components/support-messages.vue"),
            meta: {requiresAuth: true, headerMenu: "help"}
        },
        {
            path: '/advice',
            component: () => import('./components/advice.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/advice-me',
            // handled by a common page
            component: () => import('./components/advice-messages.vue'),
            meta: {requiresAuth: true, headerMenu: 'account'}
        },
        {
            path: '/advice-user',
            // handled by a common page
            component: () => import('./components/advice-messages.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/chat',
            component: () => import('./components/chat.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/chat-my-course',
            // handled by a common page
            component: () => import("./components/chat-messages.vue"),
            meta: {requiresAuth: true, headerMenu: 'account'}
        },
        {
            path: '/chat-course',
            // handled by a common page
            component: () => import("./components/chat-messages.vue"),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-users',
            component: () => import('./components/admin-users.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-therapies',
            component: () => import('./components/admin-therapies.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-therapy',
            component: () => import('./components/admin-therapy.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-book',
            component: () => import('./components/admin-book.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-chapter',
            component: () => import('./components/admin-chapter.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-section',
            component: () => import('./components/admin-section.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-exercise',
            component: () => import('./components/admin-exercise.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-tag',
            component: () => import('./components/admin-tag.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-tags',
            component: () => import('./components/admin-tags.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-template',
            component: () => import('./components/admin-template.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-images',
            component: () => import('./components/admin-images.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/admin-image',
            component: () => import('./components/admin-image.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/eval-overview', // from admin-users: seeEvaluationDetails ...
            component: () => import('./components/eval-overview.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/chronicle-overview', // ... same page called from from menu->account->chronicle
            component: () => import('./components/eval-overview.vue'),
            meta: {requiresAuth: true, headerMenu: 'account'}
        },
        {
            path: '/eval', 
            component: () => import('./components/eval.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/chronicle', // from menu
            component: () => import('./components/eval.vue'),
            meta: {requiresAuth: true, headerMenu: 'account'}
        },
        {
            path: '/eval-week',
            component: () => import('./components/eval-week.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/chronicle-week', // from menu
            component: () => import('./components/eval-week.vue'),
            meta: {requiresAuth: true, headerMenu: 'account'}
        },
        {
            path: '/eval-day',
            component: () => import('./components/eval-day.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/chronicle-day', // from menu
            component: () => import('./components/eval-day.vue'),
            meta: {requiresAuth: true, headerMenu: 'account'}
        },
        {
            path: '/eval-answers',
            component: () => import('./components/eval-answers.vue'),
            meta: {requiresAuth: true, headerMenu: 'admin'}
        },
        {
            path: '/chronicle-answers', // from menu
            component: () => import('./components/eval-answers.vue'),
            meta: {requiresAuth: true, headerMenu: 'account'}
        },
        {
            path: '/therapy-:descriptiveId/book-:book/chapter-:chapter/section-:section/exercise-:exercise',
            component: () => import('./components/exercise.vue'),
            meta: {requiresAuth: true}
        },
        {
            path: '/exercise-:exerciseId',
            component: () => import('./components/exercise.vue'),
            meta: {requiresAuth: true}
        },
        {
            path: '/therapy-:descriptiveId/book-:book/chapter-:chapter/section-:section',
            component: () => import('./components/find-exercise.vue'),
            meta: {requiresAuth: true}
        },
        {
            path: '/af',
            component: () => import('./components/af.vue'),
            meta: {requiresAuth: true}
        },
        {
            path: '/lottie',
            component: () => import('./components/lottie.vue'),
            meta: {requiresAuth: true}
        },
        // when route not found, redirect to dashboard, currently w/o warning
        {   
            path: '/:pathMatch(.*)*', 
            redirect: '/' 
        }
    ]
});

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some(record => record.meta.requiresAuth);

    const authenticated = app.config.globalProperties.$keycloak.authenticated;
    //console.log("authenticated:", authenticated);

    // fix dropdown-menus not closing
    document.querySelectorAll('.dropdown-menu').forEach( e => {
        e.classList.remove("show");
    });

    // fix backdrop of modals not closing (https://stackoverflow.com/a/62107597)
    if (to.path != from.path)
        document.querySelectorAll('.modal-backdrop').forEach( e => {
            e.remove();
        });

    if (authRequired){
        if (!authenticated){
            // TODO: remove, because probably obselete now that we check token via interceptor, see ./main.js 
            console.log("authRequired but no user -> redirect to login");

            const basePath = window.location.origin.toString();
            const redirectUri = basePath + 
                /* prune away strange keycloak postfix!
                 especially important when session expired, because then
                 'to' looks like this: 
                    'http://localhost:8081/#error=login_required
                 which will cause an error when initializing keycloak after
                 login */
                to.fullPath.replace(/[#&]error.*/, "");

            app.config.globalProperties.$keycloak.login({redirectUri});
            return;

            // FIXME: uncomment redirectPath usage at other location
            //sessionStorage.setItem('redirectPath', to.path);
        } else {
            // remove the ugly state parameter.
            // TODO: is this safe?
            if (to.hash.includes('#state=')){
                const newPath = to.fullPath.replace(/#state=.*/, "");
                next(newPath);
            }
        }
    } else {
        // FIXME
        if (to.path == "/login" && authenticated){
            console.log("refusing to go to /login - already logged in. -> redirect to /");
            next('/');
        }
    }

    next();
});

router.onError((error, to) => {
    const messageStore = useMessageStore();

    console.log(`route error: to=${to}, error=${error}`);

    if (!navigator.onLine)
        messageStore.warn(t('errors.offline'));
    else
        messageStore.warn(t('errors.unknownError'));


});

export default router;
