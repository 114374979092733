import http from "../http-common";

class Service {
    getFromDefinitionOrDefine(kind, userId, courseId, therapyId, justQueryExistence){
        return http.get(`/channels/fromDefinitionOrDefine/${kind}/${userId}/${courseId}/${therapyId}`, {
            params: {justQueryExistence}});
    }
    getOpenThenClosed(kind, userId, courseId, therapyId, onlyOpen, skip, limit){
        return http.get(`/channels/openThenClosed/${kind}/${userId}/${courseId}/${therapyId}`, {
            params: {onlyOpen, skip, limit}});
    }
    getNumberOfPendingCases(kind, courses=null){
        return http.get(`/channels/numberOfPendingCases/${kind}/${courses}`);
    }
    getNumberOfPendingAdviceCases(){
        return http.get('/channels/numberOfPendingAdviceCases');
    }
    getCourseHasOpenAdviceCases(courseId){
        return http.get(`/channels/courseHasOpenAdviceCases/${courseId}`);
    }
    /* TODO seems to be unused, remove? getIsPending(channelId){
        return http.get(`/channels/isPending/${channelId}`);
    }*/
    overtake(channelId){
        return http.put(`/channels/overtake/${channelId}`);
    }
    release(channelId){
        return http.put(`/channels/release/${channelId}`);
    }
    close(channelId){
        return http.put(`/channels/close/${channelId}`);
    }
    reopen(channelId){
        return http.put(`/channels/reopen/${channelId}`);
    }
}

export const ChannelService = new Service();
